.dataCustomTable .tableActions {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1350px) {
  .dataCustomTable .table-responsive {
    margin-top: 1rem;
    transform: rotateX(180deg);
    overflow-x: auto;
  }

  .dataCustomTable .table-responsive .table {
    transform: rotateX(180deg);
  }

  .dataCustomTable .tableActions {
    flex-direction: column;
  }
}
