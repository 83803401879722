.ModalTypeIntegration .card-selection {
  margin-left: 14px;
  margin-right: 24px;
  margin-top: 10px;

  background: #ffffff;

  height: 56px;
  border: 1px solid #a6a6a6;
  box-sizing: border-box;
  border-radius: 8px;
}

.ModalTypeIntegration .button-radio {
  margin-right: 10px;
  font-size: 16px;
  line-height: 24px;
  color: #6f6f6f;
}

.ModalTypeIntegration .button-radio input {
  width: 32px;
  height: 32px;
}
.ModalTypeIntegration .button-radio label {
  padding-left: 20px;
  padding-top: 8px;
}
