.templatesContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin: 1rem 0;
}

.templatesContainer span {
  display: block;
  font-weight: bold;
}

.MITableContainer {
  margin-top: 2rem;
}
.MITableContainer .provisionador-subtitle {
  padding: 0;
  margin-bottom: 1.5rem;
}

.MITableContainer table td,
.MITableContainer table th {
  vertical-align: middle;
  text-align: center;
  padding: 10px;
}

.MITableContainer table th {
  padding: 1rem;
}

.MITableContainer table button {
  height: 40px;
  max-width: 100px;
  border-radius: 4px;
}

.MITableContainer table .actionsTD {
  display: flex;
  justify-content: center;
  gap: 1rem;

  padding: 5px 10px;
}
