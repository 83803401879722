.firstAccessContent .settingsAccessContainer {
  border: 1px solid #e4e3e3;
  border-radius: 8px;
  padding: 1rem;

  margin: 1rem 0;
}

.firstAccessContent button {
  width: 262px;
}

.firstAccessContent .saveContainer {
  display: flex;
  justify-content: center;
}
