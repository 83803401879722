.inactivateContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.inactivateContainer .inactivateItem {
  display: flex;
  align-items: center;
  gap: 10px;
}

.inactivateContainer .inactivateItem h4 {
  margin: 0;
}
