.customTooltipButton {
  outline: unset !important;
  box-shadow: unset !important;
  padding: 0 !important;
}

.tooltip-html > .tooltip-inner {
  max-width: 500px;
  margin: 0 auto;
  white-space: normal;
  text-align: left;
  padding: 1rem 20px;
}

#button-tooltip {
  z-index: 2147483647 !important;
}
