.ModalDetailSyncDataStyle .modal-body {
  overflow-y: auto !important;
  overflow-x: clip !important;
  max-height: calc(100vh-100px);
}

.ModalDetailSyncDataStyle .modal-body .card-history {
  width: 100%;
  height: auto;
  background: #d8ebe4;
  border-radius: 8px;
}
.ModalDetailSyncDataStyle .modal-body .card-history p {
  color: #363636;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.ModalDetailSyncDataStyle .modal-body .card-history label {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #6f6f6f;
}

.ModalDetailsSyncDataUnit .div-data-integration-card {
  margin: 0 15px 1rem;
}
