.writebackPage {
  padding-right: 2rem;
  padding-left: 1rem;
}

.headerWritebackPage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerWritebackPage h1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #424040;
}

.headerWritebackPage button {
  background-color: transparent !important;
  width: auto !important;
  border: unset;
  box-shadow: unset !important;
  outline: unset !important;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.headerWritebackPage button span {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: #0f7eaa;
}

.writebackContent {
  width: 100%;
}

.writebackContent .div-body {
  margin: 1rem 0 0 !important;
}

.writebackContent .statusText {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.writebackContent .div-body.noBorder {
  border: unset !important;
}

.writebackContent .mainRow {
  width: 100%;
  margin: 0;
  gap: 24px;
}

.internContainerWBStatus {
  width: 7%;
}

.internContainerWB .row {
  margin: 0 !important;
}

.card-custom-table .title-key div {
  color: #6f6f6f;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.card-custom-table .row-bottom {
  padding: 0;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-custom-table .row-bottom div {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  min-height: 30px;
  color: #525252;
  border: unset;
}

.card-custom-table .row-bottom:last-child {
  border-bottom: unset !important;
}

.button-group-row {
  display: flex;
  gap: 1rem;
}

.button-group-row button {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}
