.reviewContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reviewItemContainer .title {
  margin-bottom: 10px;
}

.reviewItemContainer h3 {
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 26px !important;
  padding-bottom: 5px;
  border-bottom: 1px solid rgb(215, 213, 213);
}

.reviewItemContainer h4 {
  font-size: 16px;
}

.reviewContainer .div-data-integration-card-warn {
  margin: 0 0 10px !important;
}
