.customPagination .pagination {
  justify-content: flex-end !important;
}

.customPagination .pagination .visually-hidden {
  display: none;
}

.customPagination .pagination .page-link {
  margin: 3px;
  min-width: 42px;
  width: auto;
  height: 42px;

  border-radius: 4px;
  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Segoe UI";
  font-weight: 600;
  font-size: 17px;
  line-height: 19px;

  color: #2155ba;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px;
}

.customPagination .pagination .active .page-link {
  border: 0.14vmin solid #6468c8 !important;
}

.customPagination .pagination .disabled {
  cursor: not-allowed;
  pointer-events: unset;
  opacity: 0.65;
}

.customPagination .pagination .page-item:first-child .page-link span,
.customPagination .pagination .page-item:last-child .page-link span,
.customPagination .pagination .page-item:nth-child(2) .page-link span,
.customPagination .pagination .page-item:nth-last-child(2) .page-link span {
  font-size: 3rem;
  font-weight: 100;
  height: 31px;
}
