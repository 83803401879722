.toggleContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}

.toggleTitleContainer {
  font-size: 16px;
  line-height: 24px;
  color: #6f6f6f;
}

.isDisabled {
  cursor: not-allowed !important;
  pointer-events: unset;
}
