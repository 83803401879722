.settingsProfileUL,
.settingsProfileAnswer {
  font-size: 14px !important;
  line-height: 20px;
  color: #222 !important;
  font-weight: bold !important;
}

.settingsProfileUL {
  padding: 1rem 0 0 1.5rem;
}
