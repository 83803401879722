.usersTableEmptyContainer {
  display: flex;
  padding: 16px;
  margin-top: 2rem;

  font-weight: 600;

  border: 1px solid #e5da00d6;
  border-radius: 8px;
  color: #b29425;
  background: #fffb0012;
}
