.ModalDomains .card-option,
.ModalDomains .card-option-selected {
  margin: 8px 15px;
  border: 1px solid #a6a6a6;
  box-sizing: border-box;
  box-shadow: 0px 0px 2px rgba(81, 79, 78, 0.2);
  border-radius: 8px;
}
.ModalDomains .card-option-selected {
  border: 1px solid #0f7eaa;
}

.ModalDomains .card-option-selected input {
  margin-bottom: 1rem;
}

.ModalDomains .card-with-tooltip {
  display: flex;
  align-items: center;
  gap: 15px;
}
.ModalDomains .line-body {
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e4e3e3;
}
.ModalDomains .div-expand-setup {
  background-color: #f5f5f5;
  margin-bottom: 20px;
  padding: 20px;
  border-bottom: 1px solid #e4e3e3;
  border-top: 1px solid #e4e3e3;
}
