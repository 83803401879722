.btn-portal-container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 10px !important;

  height: 42px;
}

.mainCardPortalContainer {
  width: 400px;
}

@media screen and (max-width: 900px) {
  .mainCardPortalContainer {
    width: 100% !important;
  }
}

@media screen and (max-width: 600px) {
  .portalApp .mainFooter .footer-row {
    justify-content: center;
  }
}
