.SyncModalRegister .card-register {
  margin: 16px;
  padding: 16px 20px;

  height: 70px;

  cursor: pointer;
  align-items: center;

  background: #fff;

  border: 1px solid #e4e3e3;
  box-shadow: 0px 0px 2px rgba(81, 79, 78, 0.1);
  border-radius: 8px;
}

.SyncModalRegister .card-register:hover {
  border-color: #2155ba;
  transition: all 0.5s;
}
